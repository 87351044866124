<template>
  <div>
    <div>
      <b-card no-body style="padding: 10px">
        <b-form-row>
          <b-col>
            <LMap
              :zoom="zoom"
              :center="center"
              @ready="onMapReady"
              style="height: 650px"
            >
              <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
            </LMap>
          </b-col>
        </b-form-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer } from 'vue2-leaflet'
import L from 'leaflet'
import {
  checkCoordinatesRange,
  getDefaultCoordinates,
} from '@/common/Common.js'
import { /*BOX_ITEM_KIND,*/ ROUTE_COLOR } from '@/common/const.js'

export default {
  components: {
    LMap,
    LTileLayer,
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    targetVehicleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: {},
      zoom: 15,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: getDefaultCoordinates(),
    }
  },
  methods: {
    onMapReady(mapObject) {
      this.map = mapObject
      L.control.scale({ metric: true, imperial: false }).addTo(this.map)

      if (this.targetVehicleList.length > 0) {
        let minLat = this.center[0]
        let maxLat = this.center[0]
        let minLng = this.center[1]
        let maxLng = this.center[1]
        let index = 0
        let colorIndex = -1
        let overlayMaps = {}
        this.targetVehicleList.forEach((vehicleNode) => {
          if (index < 10 && vehicleNode.trips.length > 0) {
            index++
            colorIndex++
            if (colorIndex >= ROUTE_COLOR.length) {
              colorIndex = 0
            }
            let latlngs = []
            let prevLatlng = []
            let markers = []
            let placeIdsInVehicle = []
            vehicleNode.trips.forEach((trip) => {
              trip.items.forEach((item) => {
                if (
                  checkCoordinatesRange(
                    item.gantt.latitude,
                    item.gantt.longitude
                  )
                ) {
                  // 位置が異なる場合にのみデータを追加
                  // ※1か所で卸→積となる場合は最初のデータ（卸）のみデータを保持
                  if (
                    prevLatlng.length === 0 ||
                    item.gantt.latitude !== prevLatlng[0] ||
                    item.gantt.longitude !== prevLatlng[1]
                  ) {
                    prevLatlng = [item.gantt.latitude, item.gantt.longitude]
                    latlngs.push([item.gantt.latitude, item.gantt.longitude])
                    if (
                      !placeIdsInVehicle.some((placeId) => {
                        if (placeId === item.gantt.placeId) {
                          return true
                        }
                      })
                    ) {
                      placeIdsInVehicle.push(item.gantt.placeId)
                      let marker = L.marker(
                        [item.gantt.latitude, item.gantt.longitude],
                        {
                          opacity: 1,
                        }
                      )
                        .bindTooltip(this.getPositionLabel(item, index), {
                          direction: 'center',
                          permanent: true,
                          className: 'position-label',
                          offset: [-10, 50],
                        })
                        .bindPopup(this.getPositionPopup(item, index))
                      // .addTo(this.map)

                      markers.push(marker)
                    }

                    if (item.gantt.latitude < minLat) {
                      minLat = item.gantt.latitude
                    }
                    if (item.gantt.latitude > maxLat) {
                      maxLat = item.gantt.latitude
                    }
                    if (item.gantt.longitude < minLng) {
                      minLng = item.gantt.longitude
                    }
                    if (item.gantt.longitude > maxLng) {
                      maxLng = item.gantt.longitude
                    }
                  }
                }
              })
            })
            let polyline = L.polyline(latlngs, {
              color: ROUTE_COLOR[colorIndex],
            }).bindPopup(this.getVehiclePopup(vehicleNode))
            // .addTo(this.map)
            markers.push(polyline)
            overlayMaps[
              `<span style='background-color: ` +
                ROUTE_COLOR[colorIndex] +
                `; display: inline-block; width: 4px; height: 14px; margin-right: 2px;'>` +
                '　' +
                `</span><span style='font-size: 14px'>` +
                vehicleNode.vehicle.vehicleName +
                `</span>`
            ] = L.layerGroup(markers).addTo(this.map)
          }
        })
        if (index > 0) {
          this.map.fitBounds([
            [minLat, minLng],
            [maxLat, maxLng],
          ])
          L.control
            .layers(null, overlayMaps, {
              collapsed: false,
              position: 'bottomright',
            })
            .addTo(this.map)
        }
      }
    },
    getPositionLabel(item /*, index*/) {
      return item.gantt.placeName
    },
    getPositionPopup(item) {
      return (
        item.gantt.placeCode +
        '<br>' +
        item.gantt.placeName +
        '<br>' +
        (item.gantt.address || '')
      )
    },
    getVehiclePopup(vehicleNode) {
      return (
        (vehicleNode.vehicle.vehicleCode || '') +
        '<br>' +
        (vehicleNode.vehicle.vehicleName || '') +
        '<br>' +
        (vehicleNode.vehicle.carrierName || '')
      )
    },
  },
}
</script>
<style lang="scss">
.position-label {
  position: absolute;
  // padding: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0px solid #000;
  color: #ffffff;
  // white-space: nowrap;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  // pointer-events: none;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
</style>
